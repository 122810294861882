import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export const HIW = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1500, once: false });
  }, []);

  return (
    <section className="relative bg-[#FAF6F0] py-[4vw] overflow-x-hidden">
      {/* Background Heading */}
      <div className="relative">
        <h3 className="absolute top-[-5vw] left-[20vw] text-[16vw] md:text-[9.375vw] opacity-10 font-karla font-extrabold text-[#D5B376]">
          Our Process
        </h3>
      </div>

      <div className="container mx-auto px-[2vw] relative z-10">
        {/* Main Heading */}
        <h2 className="text-center font-extrabold text-[10.67vw] md:text-[6.25vw] font-garamond text-[#8C1211]">
          How It Works?
        </h2>

        {/* Subtext */}
        <p className="text-center text-[3vw] md:text-[1.25vw] font-karla font-medium text-[#1E1E1E] mt-40px-vw w-full md:w-2/3 mx-auto">
          Wish to learn at the Ustad Ghulam Mustafa Khan Academy? The opportunity is just three steps away:
        </p>
   <div className="flex flex-col md:flex-row justify-center items-center  mt-40px-vw relative px-[10.09vw]">
   <div className="w-[12vw] h-[12vw] md:w-[9.38vw] md:h-[9.38vw] rounded-full bg-white shadow-lg flex items-center justify-center">
      <img src="./images/step11.png" alt="Step 2" className="w-[7vw] md:w-[4.52vw] md:h-[4.52vw]" />
    </div>
    <div className="hidden md:flex flex-grow h-[0.1vw] bg-[#1c1c1c] w-[5vw] opacity-20 mx-0"></div>
    <div className="w-[12vw] h-[12vw] md:w-[9.38vw] md:h-[9.38vw] rounded-full bg-white shadow-lg flex items-center justify-center">
      <img src="./images/step2.png" alt="Step 3" className="w-[7vw] md:w-85px-vw  md:h-[4.52vw]" />
    </div>
    <div className="hidden md:flex flex-grow h-[0.1vw] bg-[#1c1c1c] w-[5vw] opacity-20 mx-0"></div>
   

    <div className="w-[12vw] h-[12vw] md:w-[9.38vw] md:h-[9.38vw] rounded-full bg-white shadow-lg flex items-center justify-center">
      <img src="./images/step3.png" alt="Step 1" className="w-[7vw] md:w-[6vw] md:h-[5.52vw]" />
    </div>
   
   </div>

        {/* Steps Section */}
        <div className="flex flex-col md:flex-row justify-center gap-[6.25vw] items-center  mt-40px-vw relative">
  {/* Step 1 */}
  <div className="flex flex-col items-center   text-center relative w-[24.38vw]">
   
    <h6 className="text-[2.5vw] md:text-[1.88vw] font-semibold text-[#1E1E1E] mt-[2vw] font-karla">STEP 01</h6>
        <p className="text-[3vw] md:text-[1.25vw] mt-[1vw] font-karla opacity-60 text-[#1E1E1E] h-[8.54vw]">
      <span className="font-bold text-[#8C1211]">Register:</span>  The Ustad Ghulam Mustafa Khan Academy offers a broad range of courses, catering to various interests and abilities. Choose what works best for you and register.
    </p>
  </div>



  {/* Step 2 */}
  <div className="flex flex-col items-center w-full md:w-[24.38vw]  text-center relative">
   
    <h6 className="text-[2.5vw] md:text-[1.88vw] font-semibold text-[#1E1E1E] mt-[2vw] font-karla">STEP 02</h6>
    <p className="text-[3vw] md:text-[1.25vw] mt-[1vw] font-karla opacity-60 text-[#1E1E1E]">
      <span className="font-bold text-[#8C1211] h-[8.54vw]">Enrol for the course:</span>  We have online and in-person solo sessions, conducted by the gurus individually, for courses that range from 3 months and 6 months to 1 year and 2 years.
    </p>
  </div>

  

  {/* Step 3 */}
  <div className="flex flex-col items-center w-full md:w-[24.38vw] text-center">
    
    <h6 className="text-[2.5vw] md:text-[1.88vw] font-semibold text-[#1E1E1E] mt-[2vw] font-karla">STEP 03</h6>
    <p className="text-[3vw] md:text-[1.25vw] mt-[1vw] font-karla opacity-60 text-[#1E1E1E]">
      <span className="font-bold text-[#8C1211] h-[8.54vw]">Become a student:</span> Once you enrol for the course by paying the fee, the student is intimated about the course and the way forward, followed by an induction.
    </p>
  </div>
</div>

      </div>
    </section>
  );
};

export default HIW;
