import { RxHamburgerMenu } from "react-icons/rx"; 
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { navlinks, ourProductsSublinks, ourProductsSublinks2 } from "../Data/Navlinks";
import { CgChevronDown, CgClose, CgMenu } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import logo_img from "../../Assets/img/Logo.png";
import WhiteBtn from "../../Components/WhiteBtn";
import arrowImg from "../../Assets/img/activeIconImg.png";
import tblaImg from "../../Assets/img/tablaImg.png";

const NewHeader = () => {

    const mobileLinks = [
        {
          name: "UGMK Prodigies",
          link: "/ugmk-prodigies",
        },
        {
          name: "About",
          link: "/about-us",
        },
        {
          name: "Academy",
          link: "/academy",
        },
        {
          name: "Success Stories",
          link: "/success-stories",
        },
        {
          name: "Contact Us",
          link: "/contact-us",
        },
      ];
    

  const [menuOpen, setMenuOpen] = useState(false);
  const [productsHover, setProductsHover] = useState(false);
  const [academyuHover, setAcademyHover]=useState(false);
  const [hoveredSublink, setHoveredSublink] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const [border , setBorder] = useState(false);
  const [showSidebar,setShowSidebar] = useState(false);
  
  const onclick = () => {
    navigate('/contact');
  }
   
  const forHover=(name)=>{
    if(name === "About"){
        setProductsHover(true);

    }
    if(name === "Academy"){
        setAcademyHover(true);
    }
  }

  const forHoverLeave=()=>{
    setBorder(true);
    setProductsHover(false);
    setAcademyHover(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const threshold = window.innerHeight * 1;

      if (scrollY > threshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const contactNavigate=()=>{
    navigate('/contact-us');
  }
const handleSidebar = () =>{
    setShowSidebar(!showSidebar);
    console.log("clicked",showSidebar)
}

  return (
   <div >
   <header
        className={`${
          isSticky ? "fixed top-0 left-0 right-0 md:py-20px-vw py-10px-mvw" : "md:py-40px-vw py-20px-mvw absolute left-0 right-0 "
        } z-30 mx-auto top-0 w-full  transition-all duration-300 !no-underline !list-none ${
          isSticky
            ? "bg-white text-black"
            : " text-white "
        }`}
      >
       {
        !showSidebar && 
        <nav className="w-full flex justify-between  items-center py-2px-mvw md:h-77px-vw px-16px-mvw md:px-[7.29vw] ">
        <Link to="/">
          <div className="flex flex-col justify-center items-center gap-y-[0.5vh] p-4px-vh">
            <div className="md:h-77px-vw h-40px-mvw">
              <img
                src={logo_img}
                alt="logo"
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </div>
          </div>
        </Link>

        <div className="md:hidden flex items-center space-x-4">
      
            <button >
             <RxHamburgerMenu  className={`${showSidebar ? 'hidden' : 'block'}  ${
                    isSticky ? "text-black" : "text-white"
                  } w-[8vw] h-[8vw]`}
                  onClick={handleSidebar} />
                
            </button>
          </div>
      

        <div className="hidden md:flex items-center whitespace-nowrap font-karla font-semibold text-[1.0416vw] space-x-60px-vw">
          {navlinks.map((item) => (
            <div
              key={item.name}
              className="relative flex items-center hover:text-black"
              onMouseEnter={(e) => forHover(item.name)}
              onMouseLeave={forHoverLeave}
            >
              <Link to={item.link} className="flex  items-center justify-center no-underline">
                <div
                  className={`${
                    isSticky ? "text-black" : "text-[#FFFFFF]"
                  } text-20px-vw item-name hover:text-[#D5B376] `}
                >
                  {item.name}
                </div>
              </Link>

              {item.name === "About" && (
                <div
                  className={`absolute top-full right-[-12.5vw] mt-30px-vw bg-[#FAF6F0] text-[#2B2A29] font-karla font-medium text-[1.354vw] opacity-100 shadow-lg w-[15.58vw] py-30px-vw transform transition-all duration-300 ease-in-out ${
                    productsHover ? "opacity-100 translate-y-0 visible" : "opacity-0 invisible -translate-y-4"
                  }`}
                  onMouseEnter={() => setProductsHover(true)}
                  onMouseLeave={() => setProductsHover(false)}
               
                >
                  {ourProductsSublinks.map((sublink) => (
                    <Link
                      key={sublink.id}
                      to={ `/about-us#${sublink.refid}`}
                      className="flex justify-between items-center no-underline py-[1vh] text-[#2B2A29] hover:text-black"
                      onMouseEnter={() => setHoveredSublink(sublink.id)}
                      onMouseLeave={() => setHoveredSublink(null)}
                    >
                      <div className="flex w-[80%] pl-40px-vw font-medium hover:text-[#D5B376] items-center text-[#2B2A29] justify-between">
                        <span>{sublink.name}</span>
                        {hoveredSublink === sublink.id && (
                          <div className="w-24px-vw">
                            <img
                              src={arrowImg}
                              alt="sublinkicon"
                              className="w-full h-auto"
                            />
                          </div>
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              )}
                 {item.name === "Academy" && (
                <div
                  className={`absolute top-full right-[-12.5vw] mt-30px-vw bg-[#FAF6F0] text-[#2B2A29] font-karla font-medium text-[1.354vw] opacity-100 shadow-lg w-[15.58vw] py-30px-vw transform transition-all duration-300 ease-in-out ${
                    academyuHover ? "opacity-100 translate-y-0 visible" : "opacity-0 invisible -translate-y-4"
                  }`}
                  onMouseEnter={() => setAcademyHover(true)}
                  onMouseLeave={() => setAcademyHover(false)}
                  // style={{
                  //   backgroundImage: `url(${tblaImg})`,
                  //   backgroundSize: '60% 90%',
                  //   backgroundPosition: 'right bottom',
                  //   backgroundRepeat: 'no-repeat',
                  //   opacity: '0.5',
                  //   objectFit:''

                  // }}
                >
                  {ourProductsSublinks2.map((sublink) => (
                    <Link
                      key={sublink.id}
                      to={`/academy#${sublink.refid}`}
                      className="flex justify-between items-center no-underline py-[1vh] text-[#2B2A29] hover:text-black"
                      onMouseEnter={() => setHoveredSublink(sublink.id)}
                      onMouseLeave={() => setHoveredSublink(null)}
                    >
                      <div className="flex w-[85%] pl-40px-vw font-medium hover:text-[#D5B376] items-center text-[#2B2A29] justify-between">
                        <span>{sublink.name}</span>
                        {hoveredSublink === sublink.id && (
                          <div className="w-24px-vw">
                            <img
                              src={arrowImg}
                              alt="sublinkicon"
                              className="w-full h-auto"
                            />
                          </div>
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
          
          <WhiteBtn onClick={contactNavigate} title={"Enrol now"} isSticky={isSticky} navbar={true} />
        </div>
      </nav>
       }
      </header>

   {
    showSidebar &&   <div className="w-full !h-screen !z-50 flex flex-col p-24px-mvw !overflow-hidden bg-[#FAF6F0]">
    {/* Header Section */}
    {/* <div className="flex items-center justify-between mb-[40px]">
      <div onClick={handleSidebar} >
        <img src="/mobile/mb_logo.png" alt="logo" />
      </div>
      <button onClick={handleSidebar}>
        <img src="/mobile/mb_cross.png" alt="cross"  />
      </button>
    </div> */}

    {/* Links Section */}
    {
  showSidebar && (

    <div className="relative">
  <div
    className={`fixed top-0 left-0 h-screen w-full bg-[#FAF6F0] px-[4.27vw] pt-[6.4vw] z-50 !transform !transition-transform !duration-500 !ease-in-out ${
      showSidebar ? '!translate-x-0' : '!-translate-x-full'
    }`}
  >
    {/* Header Section */}
    <div className="flex items-center justify-between mb-[40px]">
      <div onClick={handleSidebar} className="w-[28.27vw]">
        <img src={logo_img} alt="logo" className="h-full w-full object-cover" />
      </div>
      <button onClick={handleSidebar}>
        <img src="/mobile/mb_cross.png" alt="cross" />
      </button>
    </div>

    {/* Links Section */}
    <div className="flex flex-col items-start">
      {mobileLinks.map((item, index) => (
        <Link to={item.link} key={index} className="!no-underline w-full">
          <div
            className={`font-karla font-semibold text-[#1E1E1E] text-[5.33vw] py-[12px] ${
              item.name !== "Contact Us" ? "border-b-2 border-[#EDE7DF]" : ""
            }`}
            onClick={handleSidebar}
          >
            {item.name}
          </div>
        </Link>
      ))}
    </div>
  </div>
</div>

  )
}

  </div>
   }
   </div>
  );
};

export default NewHeader;
