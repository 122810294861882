import React, { useEffect } from "react";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles// Adjust the import path accordingly

const MusicSpiritual = ({ title, desc, CustomCss,writer }) => {
  useEffect(() => {
    AOS.init({ duration: 1500, once: false }); // Initialize AOS
  }, []);

  return (
    <>
      <section
        className="w-full h-full bg-[#f7f0e6]  px-3"
        style={{
          backgroundImage: `url("/home/flower_bg.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="flex items-center flex-col justify-center md:py-[11.11vh] py-[10.67vw] gap-y-[6vw] md:gap-y-30px-vh md:px-0"
          data-aos="fade-up" // AOS effect for the title and description
        >
          <h1 className="text-[10.67vw] md:text-ebgaramond-extrabold-100 text-center font-garamond font-extrabold text-[#8C1211] opacity-100">
            {title}
          </h1>
          <div className="text-center">
          <p
            className={`text-[#1E1E1E] text-center text-[6.40vw] md:text-karla-medium-54 font-karla font-medium md:px-[17vw] ${CustomCss}`}
          >
            {desc}
          </p>
          {
            writer&&<p className="font-karla font-bold text-[5.54vw] md:text-[2vw] text-[#1E1E1E]">{writer}</p>
          }
          </div>
        
         
          
        </div>
      </section>
    </>
  );
};

export default MusicSpiritual;
