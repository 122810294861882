import React, { useContext, useEffect, useRef } from "react";
import "./about.scss";
import { Container, Row, Col } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LifeUGMK } from "./lifeugmk";
import { ContextApi } from "../../App";
import LifeSlider from "./Slider";
import img1 from "../../Assets/img/Murtuja.png";
import img2 from "../../Assets/img/Hasan.png";
import img3 from "../../Assets/img/qadir.png";
import img_1 from "../../Assets/img/murtujamobile.png";
import img_2 from "../../Assets/img/hasanMobile.png";
import img_3 from "../../Assets/img/qadirMobile.png";
import OurGurusSub from "./OurGurusSub";
import OurGurusSub2 from "./OurGurusSub2";
import ReverseOurGurus from "./ReverseOurGurus";
import OurGurusMobile from "./OurGurusMobile";
import Introduction from "./Introduction";
import MusicSpiritual from "../Home/section/spiritual";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css";
import TopComponent from "../../Components/TopComponent";

const settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "5px",
  slidesToShow: 3.5,
  speed: 3000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        nav: false,
        speed: 1000,
        centerMode: true,
        autoplay: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nav: false,
        speed: 3000,
        centerMode: true,
        autoplay: true,
      },
    },
  ],
};

const AboutPage = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const location = useLocation();
 

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToSeventyPercentScreen = () => {
    window.scrollBy({ top: window.innerHeight * 0.7, behavior: "smooth" });
  };
  
  // Call this function after 2 seconds
  
  // useEffect(() => {
  //   // Scroll based on the state passed from the header (on initial load or navigation)
  //   if (location?.state?.ref === "I") {
  //     scrollToRef(ref2);
  //   } else if (location?.state?.ref === "O") {
  //     scrollToRef(ref2);
  //   } else if (location?.state?.ref === "L") {
  //     scrollToRef(ref3);
  //   } else if (location?.state?.ref === "A") {
  //     scrollToRef(ref4);
  //   }
  // }, [location]);

  useEffect(() => {
    // Listen to the URL hash to scroll to the corresponding section
    const hash = location.hash;
    if (hash === "#introduction") {
      scrollToRef(ref5);
     
     
    } else if (hash === "#gurus") {
      scrollToRef(ref2);
    } else if (hash === "#life") {
      scrollToRef(ref3);
    } else if (hash === "#awards") {
      scrollToRef(ref4);
    }
  }, [location.hash]);

  const dataa = [
    {
      img: { img1 },
      heading: "MURTUZA MUSTAFA",
      subHeading: "(Music composer and record producer)",
      p1: "Hailing from the Rampur-Sahaswan Gharana, Ghulam Murtuza Khan learnt under the able guidance of his grandfather, late Ustad Waris Hussain Khan and his father, Padma Vibhushan Ustad Ghulam Mustafa Khan. He is also a faculty at KM Music Conservatory, Chennai, a music academy by Oscar-winning composer AR Rahman. He has performed classical, Sufi and fusion music concerts across the world, including Europe and Gulf. His disciples include singer Mohammed Irrfan, actress Tabu, singer Priyani Wani Panditt and composer AR Rahman's family.  ",
      p2: " Besides doing playback for Bollywood, which includes songs like Piya Haji Ali (Fiza), Chupke Se (Saathiya), Tere Bina (Guru), Haa Rehem (Aamir), Maula Mere (Anwar), Al-Madath-Maula (Mangal Pandey) and Noor-Un-Ala-Noor (Meenaxi), he also collaborated with AR Rahman on the international project Warriors of Heaven and Earth and the play Bombay Dreams.",
      // p3: "Besides doing playback for Bollywood, which includes songs like Piya Haji Ali (Fiza), Chupke Se (Saathiya), Tere Bina (Guru), Haa Rehem (Aamir), Maula Mere (Anwar), Al-Madath-Maula (Mangal Pandey) and Noor-Un-Ala-Noor (Meenaxi), he also collaborated with AR Rahman on the international project Warriors of Heaven and Earth and the play Bombay Dreams produced by Broadways Productions (London).",
    },

    {
      img: { img2 },
      heading: "HASAN MUSTAFA",
      subHeading: "(Music composer and record producer)",
      p1: "Hailing from the coveted Rampur-Sahaswan Gharana, Hasan Mustafa Khan started his training in music as a kid. Having initially learnt from his grandfather, late Ustad Waris Hussain Khan, he later received the able guidance of his father, Padma Vibhushan Ustad Ghulam Mustafa Khan. He toured the world for Sufi and fusion music, besides solo classical music concerts.  ",
      p2: "An ace in Hindustani Classical, Geet, Ghazal and Bhajan, Hasan Mustafa Khan sang Aao Balma and Soz-o-Salam in Coke Studio 3. He is the guru to several leading musicians, including singer Shaan, his son Maahi, Arjun Kanungo, dancer Arjun Tanwar and actor Aashim Gulati.",
      
    },
    {
      img: { img3 },
      heading: "QADIR MUSTAFA",
      subHeading: "(Music composer and record producer)",
      p1: "Hailing from the Rampur-Sahaswan Gharana, Ghulam Qadir Khan started his training in music at the age of 5. An ace in Hindustani Classical, Geet, Ghazal and Bhajan, he has worked on several Bollywood projects with composers AR Rahman, Shankar-Ehsaan-Loy and Amit Trivedi. Some of his playback outings include Piya Haji Ali (Fiza), Chupke Se (Saathiya), Tere Bina (Guru), Haa Rehem (Aamir), Maula Mere (Anwar), Al-Madath-Maula (Mangal Pandey) and Noor-Un-Ala-Noor (Meenaxi), among others. ",
      p2: "Besides being a guru to several leading musicians and actors, Ghulam Qadir Khan has also been a vocal coach on reality shows such as Indian Idol, Jo Jeeta Wohi Super Star and India’s Got Talent. Some of his students include singer Armaan Malik, choreographer Terence Lewis, actress Elli Avram, singer Abhijeet Sawant, Rahul Vaidya and Prajakta Shukre.",
      p3: "",
    },
  ];

  
  return (
    <>
      <section className="ContactBanner GulamAboutwrappper ">
    

             <TopComponent 
             title={"About Us"}
             breadcrumb={"About Us"}
             img={"/bg/about.png"}
             mobileTitle={"About Us"} 
             mbImg={"/mobile/mb_about.png"}
              />


      </section>
      <section ref={ref5}>
        <Introduction bgHeading={"UGMK Academy"} heading={"Introduction"} />
      </section>

      <MusicSpiritual
        CustomCss={"md:!px-[12vw]"}
        title={"Our Belief"}
        desc={
          '"Music education must reach anyone who desires it wholeheartedly, irrespective of resources or medium."'
        }
        writer={"-Ustad Ghulam Mustafa Khan"}
      />
      <div
        className="guruTeamWrapper pt-[8vh] md:pt-[20.44vh] bg-[#FAF6F0]  "
        ref={ref2}
        id="section2"
      >
        <div className="relative">
          <h3 className="md:!text-[9.38vw] text-[16vw] md:top-[-8vh] top-[-15px]  font-extrabold absolute text-[#D5B376] opacity-10">
            Mentors
          </h3>
        </div>
        <h2
          className="text-[rgb(140,18,17)] heading mobhead1 font-extrabold pl-[4.62vw] md:pl-[7.34vw] text-[10.67vw] md:text-[6.25vw]"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          Our Gurus
        </h2>
        <p
          className="md:w-[79.60vw] w-full !text-[4.27vw]   md:!text-[1.25vw]   font-karla font-bold  pl-[4.62vw] md:pl-[7.34vw] text-[#1E1E1E]"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          The UGMK Academy is run by Ustad Ghulam Mustafa Khan’s family, and
          hence is an infallible centre for all things music. Our gurus - Ustad
          Ghulam Mustafa Khan sahab's sons Murtuza Mustafa, Qadir Mustafa and
          Hasan Mustafa - have nurtured several musicians, who are among the
          most revered names in the world of music today.
        </p>
      </div>

      <div className="hidden md:block">
        <OurGurusSub
          image={img1}
          heading={dataa[0].heading}
          subHeading={dataa[0].subHeading}
          p1={dataa[0].p1}
          p2={dataa[0].p2}
          p3={dataa[0].p3}
        />
      </div>
      <div className="block md:hidden">
        <OurGurusMobile
          image={img_1}
          heading={dataa[0].heading}
          subHeading={dataa[0].subHeading}
          p1={dataa[0].p1}
          p2={dataa[0].p2}
          p3={dataa[0].p3}
        />
      </div>
      <div className="hidden md:block">
        <ReverseOurGurus
          image={img2}
          heading={dataa[1].heading}
          subHeading={dataa[1].subHeading}
          p1={dataa[1].p1}
          p2={dataa[1].p2}
          p3={dataa[1].p3}
        />
      </div>
      <div className="block md:hidden">
        <OurGurusMobile
          image={img_2}
          heading={dataa[1].heading}
          subHeading={dataa[1].subHeading}
          p1={dataa[1].p1}
          p2={dataa[1].p2}
          p3={dataa[1].p3}
        />
      </div>
      <div className="hidden md:block">
        <OurGurusSub2
          image={img3}
          heading={dataa[2].heading}
          subHeading={dataa[2].subHeading}
          p1={dataa[2].p1}
          p2={dataa[2].p2}
          p3={dataa[2].p3}
        />
      </div>
      <div className="block md:hidden">
        <OurGurusMobile
          image={img_3}
          heading={dataa[2].heading}
          subHeading={dataa[2].subHeading}
          p1={dataa[2].p1}
          p2={dataa[2].p2}
          p3={dataa[2].p3}
        />
      </div>

      <div className="py-[6vh] bg-[#FAF6F0]"></div>
      {/* <LifeUGMK ref3={ref3} /> */}

      <LifeSlider ref3={ref3}  />
      <section className="awardArea" ref={ref4} >
        <h3>Achievements</h3>
        <Container className="custom-container">
          <h2 className="heading">Awards</h2>
        </Container>
        <div className="md:ml-[7.29vw] ml-0 pl-16px-mvw md:pl-0 w-[100vw] md:w-[68%] text-[5.54vw] text-[#1E1E1E] md:text-[1.25vw] font-karla font-medium py-20px-mvw md:py-40px-vw">
        Here's a glimpse of all the prestigious laurels that were bestowed upon Padma Vibhushan Ustad Ghulam Mustafa Khan sahab:
        </div>
        <div className="AwardSliderArea cursor-pointer">
          <Slider {...settings}>
            <div className="AwardSlide">
              <img src="/images/1.png" alt="" title="" />
              <h4>2nd Highest Civilian Award Padma Vibhushan</h4>
            </div>
            <div className="AwardSlide AwardpTop">
            <img src="/images/2.png" alt="" title="" />
              <h4>3rd Highest Civilian Award Padma Bhushan</h4>
            </div>
            <div className="AwardSlide">
            <img src="/images/3.png" alt="" title="" />
              <h4>4th Highest Civilian Award Padmashree</h4>
            </div>
            <div className="AwardSlide AwardpTop">
            <img src="/images/4.png" alt="" title="" />
              <h4>Honorable President Shri Abdul Kalam presenting Padmabhushan, the 2nd highest Civilian Award to Ustad Ghulam Mustafa Khansahab</h4>
            </div>
            <div className="AwardSlide">
            <img src="/images/5.png" alt="" title="" />
              <h4>UGMK Padma Vibhushan</h4>
            </div>
            <div className="AwardSlide AwardpTop">
            <img src="/images/6.png" alt="" title="" />
              <h4>Ustad Ghulam Mustafa Khansahab with Bharat Ratna Shri L.K Advani former Deputy Prime Minister of India</h4>
            </div>
            <div className="AwardSlide">
            <img src="/images/7.png" alt="" title="" />
              <h4>Ustad Ghulam Mustafa Khansahab with Honorable President Shri Giani Zail Singh after his performance in Goa


</h4>
            </div>
            <div className="AwardSlide AwardpTop">
            <img src="/images/8.png" alt="" title="" />
              <h4>Ustad Ghulam Mustafa Khansahab with Honorable Prime Minister Shri Narendra Modi at the Civil Investiture ceremony at Rashtrapati Bhavan in 2018</h4>
            </div>
            <div className="AwardSlide AwardpTop">
            <img src="/images/9.png" alt="" title="" />
              <h4>Padma Shri (1991) by President of India Shri R Venkatraman</h4>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
