import { CgNotes } from "react-icons/cg";
import React, { useState } from "react";
import WhiteBtn from "../../Components/WhiteBtn";
import { useNavigate } from "react-router-dom";

export const OurCourcesCard = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "";
    }
    return text;
  };
  const coursesData = [
    {
      id: 1,
      img: "./images/ustadji.png",
      heading: "HINDUSTANI CLASSICAL MUSIC",
      paragraph:
        "This course gives you an opportunity to delve into the ancient traditions of Hindustani Classical Music by learning the fundamentals of swara (notes), raga (melodic modes) and taal (rhythmic cycles). We also help you develop your skills in vocal technique, improvisation, and performance...",
      price: "₹4999/-",
      weeks: "12 Weeks",
      lessons: "20 Lessons",
      mode: " - Online",
      time: "60 Mins",
    },
    {
      id: 2,
      img: "./images/ustadji.png",
      heading: "GHAZAL AND SEMI CLASSICAL",
      paragraph:
        "To understand the art of ghazal singing, we help you discover the poetic and musical nuances of ghazal, a traditional form of Urdu poetry set to music. We help you learn the intricacies of ghazal singing, including expression, emotion and improvisation. As far as semi-classical music is concerned, we help you learn various music forms, including Thumri, Dadra and Kajri, which blend classical and folk elements. We help you develop your skills in vocal technique, expression, and performance.",
      price: "₹4999/-",
      weeks: "16 Weeks",
      lessons: "32 Lessons",
      mode: "- Online",
      time: "75 mins per session",
    },
    {
      id: 3,
      img: "./images/ustadji.png",
      heading: "FILM MUSIC",
      paragraph:
        "Perhaps the greatest passion for most music aficionados in India, we help playback singing aspirants dive into the world of Bollywood music, learning the techniques and styles of singing for films. With some of the most successful musicians of the film industry as part of our UGMK family - including superstars such as AR Rahman, Sonu Nigam, Hariharan and Shaan, etc., - we definitely ace this space. In fact, our gurus, too, have some of the biggest Bollywood hits to their credit.",
      price: "₹4999/-",
      weeks: "20 Weeks",
      lessons: "40 Lessons",
      mode: "- Online",
      time: "90 mins per session",
    },
    {
      id: 4,
      img: "./images/ustadji.png",
      heading: "DEVOTIONAL MUSIC",
      paragraph:
        "If you wish to learn the art of singing devotional music from the heart, we help you explore the spiritual and emotional depths of the genre, including Bhajans and Kirtans. Besides technical knowledge, we also believe in imparting an understanding of the significance of devotional singing. Sufi music is also a key part of our devotional music curriculum.",
      price: "₹4999/-",
      weeks: "14 Weeks",
      lessons: "28 Lessons",
      mode: "- Online",
      time: "60 mins per session",
    },
  ];

  return (
    <div>
      {/* Heading Section */}
      <div className="relative">
        <div className="top-[0vh] left-0 text-[16vw] md:text-[9.375vw] absolute opacity-10 font-karla font-extrabold text-[#D5B376]">
          Student Programs
        </div>
      </div>
      <div className="pb-[6.93vw] md:pb-[0vh] pt-[5vw]">
        <div className="font-extrabold text-[10.67vw] md:pl-[7.29vw] md:text-[6.25vw] font-garamond text-[#8C1211]">
          Our Courses
        </div>
      </div>

      {/* Courses List */}
      {coursesData.map((course, index) => (
        <div
  key={course.id}
  className={`flex md:flex-row flex-col items-center justify-center md:h-auto  md:min-h-[44.73vw] mx-12px-mvw   md:w-[87%] w-[95.28vw] md:mx-[7.29vw] bg-[#F6EFE2] mb-[5vw] ${
    index % 2 === 0 ? " md:flex-row" : "md:flex-row-reverse"
  }`}
>
  {/* Image Section */}
  <div className="w-[50%] h-auto hidden md:flex items-start justify-end md:relative">
    <img
      src={course.img}
      alt={course.heading}
      className={`absolute pb-2 w-[43.28vw] h-[32.55vw] transition-all duration-300 ease-in-out ${
        index % 2 !== 0 ? "right-[-2vw]" : "left-[-3vw]"
      } ${expanded[course.id] ? "bottom-[-24.2vw]" : "bottom-[-22.5vw]"}`}
    />
  </div>

  <div className="md:hidden block w-[98.33vw] h-[73.33vw] mx-16px-mvw">
    <img src={course.img} alt="" className="w-full h-full items-center" />
  </div>

  {/* Content Section */}
  <div
    className={`md:w-[50%] w-full h-full md:py-80px-vw px-20px-mvw md:px-0 mt-[30vw] md:mt-0 flex flex-col justify-start items-start ${
      index % 2 !== 0 ? "md:pl-80px-vw" : ""
    }`}
  >
    <h1 className="md:text-[2.08vw] text-[5.56vw] font-karla text-[#1E1E1E] font-bold uppercase">
      {course.heading}
    </h1>
    <p className="pr-80px-vw pt-24px-vw pb-24px-vw text-[4.44vw] md:text-[1.15vw] text-[#1E1E1E] font-karla font-medium">
      {expanded[course.id] ? course.paragraph : truncateText(course.paragraph, 40)}
      {!expanded[course.id] && "..."}
      <button
        className="text-[#8C1211] font-bold text-[4vw] md:text-[1vw] underline"
        onClick={() => toggleExpand(course.id)}
      >
        {expanded[course.id] ? "SHOW LESS" : "KNOW MORE"}
      </button>
    </p>

    <h1 className="text-[5.56vw] md:text-[2.8vw] flex items-center justify-center gap-20px-vw font-karla font-bold text-[#8C1211]">
      {course.price} <span className="md:text-[1.25vw] text-[4.44vw] text-[#1c1c1c] font-karla font-medium">(+18% GST)</span>
    </h1>
    <button className="md:py-32px-vw py-40px-mvw">
      <WhiteBtn title={"ENROLL NOW"} onClick={() => navigate("/about-us")} />
    </button>
    <div className="md:w-[40vw] w-full md:h-[0.1vw] h-[1px] bg-[#707070] opacity-40 mb-[2vw]"></div>

    {/* Course Details */}
    <div
      className={`flex flex-wrap items-center md:gap-[45px] gap-[30px] justify-start md:pt-0 mb-[40px] md:mb-0 pt-[40px] ${
        index % 2 !== 0 ? "" : "md:pr-[7.15vw]"
      }`}
    >
      <div className="flex items-center justify-center md:gap-1 gap-3 flex-nowrap text-[5.56vw] md:text-[1.25vw] font-medium font-karla text-[#1E1E1E]">
        <div className="flex items-center justify-center w-[48px] h-[48px] md:w-[48px-vw] md:h-[48px-vw] bg-white border border-[#8C1211] rounded-full">
          <img src="./AcademyIcon/1.svg" alt="" className="w-[30px] md:w-24px-vw md:h-31px-vw" />
        </div>
        {course.weeks}
      </div>

      <div className="flex items-center justify-center gap-3 flex-nowrap text-[5.56vw] md:text-[1.25vw] font-medium font-karla text-[#1E1E1E]">
        <div className="flex items-center justify-center w-[48px] h-[48px] md:w-[48px-vw] md:h-[48px-vw] bg-white border border-[#8C1211] rounded-full">
          <img src="./AcademyIcon/3.svg" alt="" className="w-[30px] md:w-24px-vw md:h-31px-vw" />
        </div>
        {course.lessons}
      </div>

      <div className="text-[5.56vw] items-center justify-center md:text-[1.25vw] font-medium font-karla text-[#1E1E1E] flex-nowrap">
        <span className="text-[#8C1211]">Mode</span> {course.mode}
      </div>

      <div className="flex flex-nowrap items-center justify-center gap-3 text-[5.56vw] md:text-[1.25vw] font-medium font-karla text-[#1E1E1E]">
        <div className="flex items-center justify-center w-[48px] h-[48px] md:w-[48px-vw] md:h-[48px-vw] bg-white border border-[#8C1211] rounded-full">
          <img src="./AcademyIcon/2.svg" alt="" className="w-[30px] md:w-24px-vw md:h-31px-vw" />
        </div>
        {course.time}
      </div>
    </div>
  </div>
</div>

))}

    </div>
  );
};
