import React, { useEffect, useRef } from "react";
import "./academy.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link, useLocation } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs";
import TopComponent from "../../Components/TopComponent";
import Introduction from "../About/Introduction";
import OurProcess from "../Home/section/process";
import HIW from "./HIW";
import WhyIntroductiontion from "./WhyIntroduction";
import { OurCourcesCard } from "./OurCourcesCard";
import { WorkShopSlider } from "./WorkShopSlider";
import MediaSlider from "../SuccessStory/MediaSlider";
import { AcademySlider3 } from "./AcademySlider3";


const Academy = () => {
    const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const location = useLocation();
 

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToSeventyPercentScreen = () => {
    window.scrollBy({ top: window.innerHeight * 0.7, behavior: "smooth" });
  };


  useEffect(() => {
    // Listen to the URL hash to scroll to the corresponding section
    const hash = location.hash;
    if (hash === "#why-us") {
      scrollToRef(ref5);
    } else if (hash === "#course") {
      scrollToRef(ref2);
    } else if (hash === "#word") {
      scrollToRef(ref3);
    } else if (hash === "#media") {
      scrollToRef(ref4);
    }
  }, [location.hash]);
    return (
        <>
            <section className="ContactBanner">
            <TopComponent 
             title={"Academy"}
             breadcrumb={"Academy"}
             img={"/bg/contact.png"}
             mobileTitle={"Academy"} 
             mbImg={"/bg/contact.png"}
              />
           <section ref={ref5}>
           <Introduction bgHeading={"About Academy"} heading={"About"} paragraph={"Rooted in the value system of honesty, self-belief and humility professed by Padma Vibhushan Ustad Ghulam Mustafa Khan, the Academy aims at offering an opportunity to anybody who aspires to sing. Be it a 4-year-old or someone who's 80 -- we believe music is the right of anyone who dreams to sing, it's for one and all. Hence, our Academy offers a one-of-a-kind opportunity to learn a range of genres from Ustad Ghulam Mustafa Khan's sons, who form the faculty of the Academy."} academy={true} img={true} />
           </section>
             <div className="hidden md:block pt-[6.25vw]">
             <HIW/>
             </div>
             <div className="block md:hidden">
             <OurProcess/>
             </div>
          
          <WhyIntroductiontion/>
          <section ref={ref4}>
          <OurCourcesCard/>
          </section>
          
         <section ref={ref2}> <WorkShopSlider/></section>
           <section ref={ref4}> 
           <MediaSlider title={"Video & Image Gallery"}/>
           </section>
           <div className="w-full   items-center justify-center">
           <div className="md:w-[67.71vw] w-[85vw] ml-[7vw] md:mx-[16.15vw] h-[1px] bg-[#8C1211] opacity-30 mb-100px-vw"></div>
           </div>
          
           <AcademySlider3/>
            </section>
           
        </>
    )
}

export default Academy