import "./testimonial.scss";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { icons } from "../../Assets/imageConstants";
import { initialImages } from "../../lib/homeData";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css";

const Testimonial = () => {
  const [images, setImages] = useState(initialImages);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      moveItems();
    }, 15000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    AOS.init({ duration: 1500, once: false }); // Initialize AOS
  }, []);

  const moveItems = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  };

  const handleItemClick = (index) => {
    setCurrentIndex(index);
  };

  const getCircularIndex = (index, length) => {
    return (index + length) % length;
  };

  return (
    <>
      <section className="TestimonialArea">
        <div className="testimonialHeading">
          <h3 className="block text-[#D5B376] opacity-10 font-karla font-extrabold text-[16vw] whitespace-nowrap overflow-hidden md:hidden">
            Happy Students
          </h3>
          <h3 className="hidden md:block">Happy Students</h3>

          <h2
            className="heading"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="150"
          >
            Testimonials
          </h2>
        </div>
        <Container
          className="custom-container"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <div className="testimonialSlider">
            <div className="SliderContent">
              <div className="Quote">
                <img src={icons.Rightquote} alt="" title="" />
              </div>
              <p>
                <div className="text-[4vw] md:text-[1.3vw]">
                  {images[currentIndex].desc}
                </div>
              </p>
              <div className="QuoteRight">
                <img src={icons.Leftquote} alt="" title="" />
              </div>
            </div>
            <div className="carousel-container">
              <div className="d-flex flex-column">
                <div className="carousel-items">
                  {Array(5)
                    .fill()
                    .map((_, index) => {
                      const imageItem =
                        images[getCircularIndex(currentIndex + index - 2, images.length)];
                      return (
                        <div
                          key={index}
                          className={`${
                            index === 2 ? "center my-5" : "opacity"
                          } ${
                            index === 0 || index === 4 ? "end" : ""
                          } ${index === 1 || index === 3 ? "d-md-block d-lg-block" : ""}`}
                          onClick={() =>
                            handleItemClick(getCircularIndex(currentIndex + index - 2, images.length))
                          }
                        >
                          <img
                            src={imageItem.img}
                            alt="item"
                            className="testi_img"
                          />
                        </div>
                      );
                    })}
                </div>
                <div>
                  <div className="description">
                    <h2>{images[currentIndex].name}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Testimonial;
