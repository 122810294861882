import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { icons } from "../../../Assets/imageConstants";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css";
import WhiteBtn from "../../../Components/WhiteBtn";

const settings = {
  dots: false,
  nav: true,
  infinite: true,
  speed: 1000,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nav: false,
        speed: 1000,
        centermode: true,
        // autoplay: true,
      },
    },
  ],
};

const OurProcess = () => { 
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1500, once: false }); // Initialize AOS
  }, []);

  return (
    <>
      <section className="howItWorks relative">
        <h3 className="absolute md:!top-[4vw] !-top-[0vw]">Our Process</h3>
        <Container
          className="custom-container"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          <Row>
            <Col md={5} sm={12}>
              <div className="leftContent ">
                <h2 class="heading md:!w-[70%]">How it Works?</h2>
                <p className="UGMKpera !pt-6  !font-karla md:!pt-0 !font-medium !text-[4.27vw] md:!text-[1.5vw] md:!w-[105%] !w-full">
                  Wish to learn at the Ustad Ghulam Mustafa Khan Academy? The
                  opportunity is just three steps away:
                </p>

                <Link className="custonBtn" to="/academy">
                  Know More
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.198"
                      height="18.38"
                      viewBox="0 0 26.198 18.38"
                    >
                      <path
                        id="Path_33587"
                        data-name="Path 33587"
                        d="M16.129,3V27.387m0,0,8.129-8.129m-8.129,8.129L8,19.258"
                        transform="translate(-2.25 25.319) rotate(-90)"
                        fill="none"
                        stroke=""
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </Col>
            <Col md={7} sm={12}>
              <div className="stepsBox">
                <ul>
                  <li>
                    <div className="circleBox">
                      <img src="./images/step11.png" alt="" title="" className="text-[#8C1211]"/>
                    </div>
                    <div className="stepDetail">
                      <h6>STEP 01</h6>
                      <p className="UGMKpera !font-medium opacity-60">
                        <span className="text-[#8C1211]">Register:</span> The Ustad Ghulam Mustafa Khan Academy offers a
                        broad range of courses, catering to various interests
                        and abilities. Choose what works best for you and
                        register.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="circleBox">
                      <img src="./images/step2.png" alt="" title="" />
                    </div>
                    <div className="stepDetail">
                      <h6>STEP 02 </h6>
                      <p className="opacity-60">
                        <span className="text-[#8C1211]">Enrol for the course:</span> We have online and in-person solo
                        sessions, conducted by the gurus individually, for
                        courses that range from 3 months and 6 months to 1 year
                        and 2 years.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="circleBox">
                      <img src="./images/step3.png" alt="" title="" />
                    </div>
                    <div className="stepDetail">
                      <h6>STEP 03 </h6>
                      <p className="opacity-60">
                        <span className="text-[#8C1211]">Become a student:</span> Once you enrol for the course by
                        paying the fee, the student is intimated about the
                        course and the way forward, followed by an induction.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="UGMKslider">
            <Slider {...settings}>
              <div className="UGMKslideM">
                <div className="IconBox">
                  {" "}
                  <img src="./images/step11.png" alt="" title="" />
                </div>
                <div className="textBox">
                  <h6>STEP 01</h6>
                  <p>
                    Register: The Ustad Ghulam Mustafa Khan Academy offers a
                    broad range of courses, catering to various interests and
                    abilities. Choose what works best for you and register.
                  </p>
                </div>
              </div>
              <div className="UGMKslideM">
                <div className="IconBox">
                  {" "}
                  <img src="./images/step2.png" alt="" title="" />
                </div>
                <div className="textBox">
                  <h6>STEP 02</h6>
                  <p>
                    Enrol for the course: We have online and in-person solo
                    sessions, conducted by the gurus individually, for courses
                    that range from 3 months and 6 months to 1 year and 2 years.
                  </p>
                </div>
              </div>
              <div className="UGMKslideM">
                <div className="IconBox">
                  {" "}
                  <img src="./images/step3.png" alt="" title="" />
                </div>
                <div className="textBox">
                  <h6>STEP 03</h6>
                  <p>
                    Become a student: Once you enrol for the course by paying
                    the fee, the student is intimated about the course and the
                    way forward, followed by an induction.
                  </p>
                </div>
              </div>
            </Slider> 
            <div className=" md:hidden mt-20px-mvw flex items-center justify-center">
            <WhiteBtn title={"Know More"} onClick={()=>navigate("/academy")}/>
            </div>
            
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurProcess;
