import React from 'react'
import TopComponent from '../../Components/TopComponent'

export const NewsEvents = () => {
  const cardsData = [
    { date: "Jan 15, 2024", content: "This two-day event in Mumbai celebrates Ustad Ghulam Mustafa Khan's music",img:"./images/img111.png" },
    { date: "Jan 19, 2024", content: "Ustad Zakir Hussain conferred upon the Padma Vibhushan Ustad Ghulam Mustafa Khan Award!",img:"./News/2.png" },
    { date: "Jan 15, 2024", content: "Talat Aziz Exclusive Interview on Ustad Ghulam Mustafa Khan 3rd Barsi at Mumbai" ,img:"./News/3.png",content:"This two-day event in Mumbai celebrates Ustad Ghulam Mustafa Khan's music"},
    { date: "Jan 19, 2024", content: "",img:"./images/img111.png" },
    { date: "Jan 19, 2024", content: "Ustad Zakir Hussain conferred upon the Padma Vibhushan Ustad Ghulam Mustafa Khan Award!" ,img:"./News/2.png"},
    { date: "Jan 15, 2024", content: "Talat Aziz Exclusive Interview on Ustad Ghulam Mustafa Khan 3rd Barsi at Mumbai" ,img:"./News/3.png"},
  ];
  return (
    <div>
        <section className='ContactBanner'>
        <TopComponent 
             title={"News & Events"}
             breadcrumb={"News & Events"}
             img={"/bg/about.png"}
             mobileTitle={"News & Events"} 
             mbImg={"/bg/contact.png"}
              />
        </section>
        <div className='pt-[6.25vw]'>
        <div className="relative md:py-40px-vw">
        <h3 className="absolute top-[-2vw] left-0 text-[16vw] md:text-[9.375vw] opacity-10 font-karla font-extrabold text-[#D5B376]">
        News
        </h3>
      </div>
      <h2 className="text-start px-[7.29vw] font-extrabold text-[10.67vw] md:text-[6.25vw] font-garamond text-[#8C1211]">
      Articles
        </h2>
        </div>
        <div className='w-full flex items-center justify-center md:py-40px-vw'>
          <img src="./images/newsImg.png" alt="" className='md:w-[85vw] w-[95.28vw] h-[111.11vw] md:h-[33.02vw] object-cover' />
        </div>
       <div className='px-[7.29vw]'>
       <div>
          <p className='font-karla font-medium text-[4.44vw] md:text-[1.25vw] opacity-60 text-[#1E1E1E]  pt-12px-mvw md:pt-12px-vw'>Jan 14, 2024</p>
          <p className='font-karla font-semibold text-[5.56vw] md:text-[1.75vw] text-[#1E1E1E]'>Ustad Zakir Hussain to receive Ustad Ghulam Mustafa Khan Award</p>
        </div>
        <div className="grid pt-60px-vw grid-cols-1  md:grid-cols-3 md:gap-x-[3.125vw] gap-y-[4.17vw]">
      {cardsData.map((card, index) => (
        <div key={index} className="md:w-[26.4vw] w-full px-12px-mvw md:px-0">
          <img src={card.img} alt="" className="md:w-[26.4vw] w-full h-[97.78vw] md:h-[19.06vw] object-cover" />
          <div className="font-karla font-medium text-[4.44vw] md:text-[1.25vw] opacity-60 text-[#1E1E1E]  pt-12px-mvw md:pt-12px-vw">
            {card.date}
          </div>
          {card.content && (
            <div className="font-karla font-semibold text-[5.56vw] md:text-[1.75vw] text-[#1E1E1E]">
              {card.content}
            </div>
          )}
        </div>
      ))}
    </div>
    <div className='md:my-[5vw] mb-[8vw] w-full items-center text-[4vw] md:text-[1.25vw]  text-center underline font-karla font-medium justify-center text-[#8C1211]'>LOAD MORE</div>
       </div>
    </div>
  )
}
