import { RxCross1 } from "react-icons/rx";
import { BiMinus, BiPlus } from "react-icons/bi";
import React, { useState } from "react";
import "./ugmkprodigies.scss";
import TopComponent from "../../Components/TopComponent";
import MediaSlider from "../SuccessStory/MediaSlider";

const dummyData1 = [
  {
    id: 1,
    image: "./Story/1.png",
    name: "AR Rahman ",
    title: "(Indian musical composer and record producer)",
    description: "A beloved student of Ustad Ghulam Mustafa Khan sahab, Padma Bhushan AR Rahman is an Oscar-winning composer, who is known for his works in Indian cinema, predominantly in Tamil and Hindi films. He has two Academy Awards, two Grammy Awards, six National Film Awards, a BAFTA Award and a Golden Globe Award to his credit. ",
  },
  {
    id: 2,
    image: "./Story/2.png",
    name: "Hariharan ",
    title: "(Indian musical composer and record producer)",
    description: "One of India's most notable voices in playback, bhajan and ghazal, Hariharan has sung over 15,000 songs in over 10 languages in his decorated career of many decades. The Padma Shri recipient has two National Film Awards to his credit. He credits his career hugely to the learnings of Hindustani Classical Music he got from his guru, Ustad Ghulam Mustafa Khan.",
  },
  {
    id: 3,
    image: "./Story/4.png",
    name: "Sonu Nigam",
    title: "(Indian musical composer and record producer)",
    description: "Sonu Nigam is a legendary singer and music director, who has made an indelible mark on the music industry. His impressive repertoire has earned him numerous awards, including the coveted Padma Shri, India's fourth-highest civilian award. The National Film Award winner has been among the dearest students of Ustad Ghulam Mustafa Khan.",
  },
];

const dummyData2 = [
  {
    id: 4,
    image: "./Story/3.png",
    name: "Shaan",
    title: "(Indian musical composer and record producer)",
    description: "Shaan is a multi award-winning playback singer and composer, who has several hits to his credit. One of the dearest students of Ustad Ghulam Mustafa Khan, Shaan learnt from the veteran for many years. Some of Shaan’s most popular numbers include Chand Sifarish, Koi Kahe Kehta Rahe, Jab Se Tere Naina and Main Hoon Don, among others. ",
  },
 
];

const UgmkProdigies = () => {
  const [selectedStory1, setSelectedStory1] = useState(null);
  const [selectedStory2, setSelectedStory2] = useState(null);

  return (
    <section className="ContactBanner relative">
      <TopComponent
        title="UGMK Prodigies"
        breadcrumb="UGMK Prodigies"
        img="/bg/contact.png"
        mobileTitle={
          <>
            UGMK 
            <br /> Prodigies
          </>
        }
        mbImg="/bg/contact.png"
      />

      <div className="relative">
        <div className="top-[6.20vw] left-0 text-[16vw] md:text-[9.375vw] absolute opacity-10 font-karla font-extrabold text-[#D5B376]">
        Thriving Legacy
        </div>
      </div>

      <div className="md:px-[7.65625vw] px-17px-mvw pt-[10.94vw]">
        <div className="font-extrabold text-[10.67vw] md:text-[6.25vw] font-garamond text-[#8C1211]">
        UGMK Prodigies
        </div>

        {/* Common Function to Render Each Section */}
        {[
          { data: dummyData1, selectedStory: selectedStory1, setSelectedStory: setSelectedStory1 },
          { data: dummyData2, selectedStory: selectedStory2, setSelectedStory: setSelectedStory2 },
        ].map(({ data, selectedStory, setSelectedStory }, index) => (
          <div key={index} className="md:mt-[6.25vw]">
            {/* Desktop View: Grid Layout */}
            <div className="hidden md:grid grid-cols-3 gap-8">
              {data.map((story) => (
                <div className="flex flex-col items-center w-auto" key={story.id}>
                  <div className="h-[34.84vw] w-[27.14vw]">
                    <img
                      src={story.image}
                      alt={story.name}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="flex items-center justify-between w-[27.14vw] py-[1.5vw]">
                    <div className="font-karla font-semibold text-[#1E1E1E] text-[1.875vw]">
                      {story.name}
                    </div>
                    <div
                      onClick={() =>
                        setSelectedStory(selectedStory?.id === story.id ? null : story)
                      }
                      className="border border-[#1E1E1E] flex items-center justify-center text-[#8C1211] rounded-full w-[3vw] h-[3vw] text-[2vw] cursor-pointer"
                    >
                      {selectedStory?.id === story.id ? <BiMinus /> : <BiPlus />}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Description Card Appears After Row in Desktop */}
            {selectedStory && (
              <div className="hidden md:flex w-full justify-center mt-6">
                <div className="w-[85.42vw] bg-[#F6EFE2] px-[2vw] py-[2vw]">
                  <div className="w-full flex items-center justify-between">
                    <h1 className="text-[#8C1211] text-[1.875vw]">{selectedStory.name}</h1>
                    <RxCross1
                      className="w-[1vw] h-[1vw] text-[#000000] font-bold cursor-pointer"
                      onClick={() => setSelectedStory(null)}
                    />
                  </div>
                  <div className="text-[#000000] text-[1.25vw] font-karla py-[0.5vw] font-medium">
                    {selectedStory.title}
                  </div>
                  <p className="text-[#010101] text-[1.04vw] font-karla opacity-60">
                    {selectedStory.description}
                  </p>
                </div>
              </div>
            )}

            {/* Mobile View: Single Column Layout */}
            <div className="grid grid-cols-1 gap-[6vw] md:hidden pt-[6.25vw]">
              {data.map((story) => (
                <div className="flex flex-col items-center w-[90.26vw]" key={story.id}>
                  <div className="h-[95.05vw] w-[90.26vw]">
                    <img
                      src={story.image}
                      alt={story.name}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="flex items-center justify-between w-[90.26vw] py-[2vw]">
                    <div className="font-karla font-semibold text-[#1E1E1E] text-[8vw]">
                      {story.name}
                    </div>
                    <div
                      onClick={() =>
                        setSelectedStory(selectedStory?.id === story.id ? null : story)
                      }
                      className="border border-[#1E1E1E] flex items-center justify-center text-[#8C1211] rounded-full w-[8vw] h-[8vw] text-[12vw] cursor-pointer"
                    >
                      {selectedStory?.id === story.id ? <BiMinus /> : <BiPlus />}
                    </div>
                  </div>

                  {/* Description Appears Below the Row in Mobile */}
                  {selectedStory?.id === story.id && (
                    <div className="w-[90.26vw] bg-[#F6EFE2] px-[4vw] py-[4vw] sm:hidden">
                      <h1 className="text-[#8C1211] text-[7.5vw]">{selectedStory.name}</h1>
                      <div className="text-[#000000] text-[5.5vw] font-karla py-[2vw] font-medium">
                        {selectedStory.title}
                      </div>
                      <p className="text-[#010101] text-[4vw] font-karla opacity-60">
                        {selectedStory.description}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      
      <MediaSlider />
    </section>
  );
};

export default UgmkProdigies;
