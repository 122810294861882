import {icons} from '../Assets/imageConstants';
import img1 from '../Assets/ImgWithBg/prodigi1.png';
import img2 from '../Assets/ImgWithBg/prodogi2.png';
import img3 from '../Assets/ImgWithBg/prodigi3.png';
export const ugmk_prodigies = [
    {
        img:img1,
        name:'AR RAHMAN',
        description:'(Indian musical composer and record producer)'
        
    },
    {
        img:img2,
        name:'HARIHARAN',
        description:'(Indian singer-songwriter and actor)'

    },
    {
        img:img3,
        name:'SHAAN',
        description:'(Indian music director and singer)'

    },
]

export const initialImages = [
    {
      img: icons.Thumbnail,
      post:"(Student, Singer)",
      name: "RASHA THADANI",
      desc: `I have been learning from Qadir sir since I was 8. Our classes have always been a perfect balance of knowledge and fun. 
             From learning a variety of songs to understanding the technicalities of each sur-scale or note — everything I know about music today is because of Qadir sir. 
             He has not only been a constant inspiration for all his students, but is also someone I have looked up to. 
             His compassion, patience, and leniency with each student is heartwarming. I feel proud that I’m a student of Qadir sir. He’s my guru and family.`
    },
    {
      img: icons.Thumbnail1,
      post:"(Teacher, Singer)",
      name: "SHEFALI ALVARES",
      desc: `Learning music with Qadir sir has been nothing short of a transformative experience. 
             His unique teaching methods made even the most complex musical concepts easy to grasp. 
             From classical ragas to modern melodies, every lesson is a beautiful blend of tradition and innovation. 
             Qadir sir encourages every student to explore their own musical journey while ensuring a solid foundation. 
             His dedication and endless patience make learning from him a joyful experience. I feel fortunate to call him my teacher.`
    },
    {
      img: icons.Thumbnail2,
      post:"(Student, Singer)",
      name: "PRIYANI VANI PANDITT",
      desc: `Qadir sir is not only a teacher but a true mentor who guides his students with immense patience and understanding. 
             His teaching goes beyond technical knowledge and delves into the soul of music, making sure that every student not only learns but lives the music. 
             Whether it’s breaking down complicated scales or helping us perform with confidence, Qadir sir always knows the best way to explain things. 
             His lessons are filled with wisdom, and each one brings us closer to mastering this beautiful art.`
    },
    {
      img: icons.Thumbnail3,
      post:"(Student, Singer)",
      name: "ARMAAN MALIK",
      desc: `I’ve been blessed to have Qadir sir as my guide from a very young age. His teaching style is exceptional, blending technical mastery with emotional depth. 
             Every lesson is filled with valuable insights, and he always encourages us to strive for excellence. 
             Under his guidance, I’ve learned to approach music not just as a performer but as an artist. His patience and kindness are unmatched, and I truly feel lucky to call him my guru.`
    },

    // 
    {
      img: "./images/Maahi.png",
      post:"(Student, Singer)",
      name: "Maahi ",
      desc: `I am a student at the UGMK Academy and I have been learning from Hasan sir for the last 10 years, since I was 9. He is my only source of formal music training and his impact on my music has been life-changing. Sir has completely built my foundation. As an artiste, I have started putting out my music now, and I owe all of it to Hasan sir and the UGMK Academy.`
    },
    {
      img: "./images/MohammedIrfan.png",
      post:"(Student, Singer)",
      name: "Mohammed Irfan ",
      desc: `When I had started learning music from my guru, Murtuza sir, my was very young and my voice was very thin. I was very concerned about my vocal tone. He gave me an appropriate riyaaz for that and it really benefitted me. I am extremely grateful to him for all the help he offered to make me a better singer.`
    },
    {
      img: "./images/ArjunTanwar.png",
      post:"(Student, Singer)",
      name: "Arjun Tanwar",
      desc: `My guru, Hasan sir, is an amazing teacher. I have been learning from him for a year now. When I came to him, I was raw. I knew I could sing, but my focus was mostly on projection. He taught me control. He made me understand the elements of my voice and cultured my voice so well. It's an honour for me to be learning from him. He has taught me voice modulation so beautifully. I am about to release my first single next month and sir's feedback matters to me a lot. He always gives me a reality check.`
    }
  ];