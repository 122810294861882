import { RxHamburgerMenu } from "react-icons/rx";
import { NavDropdown } from "react-bootstrap";
import "./header.scss";
import "./custom.css";
import { icons } from "../Assets/imageConstants";
import img from "../Assets/img/menuImg.png"
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Xicon from "../Assets/img/sidebarXicon.svg";
import hamburgerImg from "../Assets/img/whiteHamburger.svg";
import "bootstrap/dist/js/bootstrap.bundle.min"; // Import Bootstrap JS bundle
import { Offcanvas } from "bootstrap"; // Import only Offcanvas

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [image, setImage] = useState(img);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const location = useLocation();
  const navigate = useNavigate();

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const screenHeight = window.innerHeight;
      setScrolled(offset > screenHeight * 1); // 30% of the screen height
    };

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  const enableScrolling = () => {
    document.body.style.overflow = "auto"; // Allow scrolling
    document.body.style.position = "static"; // Reset any fixed position
  };
  

  // useEffect(() => {
  //   const refreshPage = () => { 
  //     closeSidebarAndBackdrop();  
  //     enableScrolling();
  //     document.body.style.overflow = "auto !important" ;
  //     console.log("page refresh");
  //   };

  //   refreshPage();
  // }, [location]); 

  // Close sidebar and remove backdrop
  const closeSidebarAndBackdrop = () => {
    const offcanvasElement = document.getElementById("navbarOffcanvas");

    if (offcanvasElement) {
      const bsOffcanvas = Offcanvas.getInstance(offcanvasElement); // Get the Offcanvas instance

      if (bsOffcanvas) {
        bsOffcanvas.hide(); // Close the offcanvas
      }
    }

    // Ensure the backdrop is removed and scroll lock is released
    const backdrop = document.querySelector(".offcanvas-backdrop");
    if (backdrop) {
      backdrop.remove();
    }

    document.body.classList.remove("offcanvas-open"); // Remove scroll lock

    // Additional safeguard to remove any lingering backdrop
    setTimeout(() => {
      document.body.style.overflow = "auto"; // Enable scrolling
      document.body.style.paddingRight = "0px"; // Remove padding added by Bootstrap
    }, 200); // Delay slightly to ensure offcanvas is fully closed
  };

  useEffect(() => {
    const enforceScroll = () => {
      document.body.style.overflow = "auto";
      document.body.classList.remove("offcanvas-open");
    };

    // Listen for scroll lock issues after navigation
    window.addEventListener("scroll", enforceScroll);

    // Cleanup on unmount
    return () => window.removeEventListener("scroll", enforceScroll);
  }, []);

  const scrollToSection = (ref) => {
    closeSidebarAndBackdrop(); // Close sidebar and remove backdrop
    navigate(`/about-us#action/${ref}`, { state: { ref } });
  };

  return (
    <div
      className={`header ${
        scrolled ? "newClass" : ""
      } transition-colors duration-300`}
    >
      <nav className="navbar navbar-expand-xl navbar-dark gulamHeaderwrapper">
        <div className="container custom-container">
          <Link
            to="/"
            className="navbar-brand Logo"
            onClick={closeSidebarAndBackdrop}
          >
            <img src={icons.Logo} alt="Logo" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarOffcanvas"
            aria-controls="navbarOffcanvas"
            onClick={() => setIsOpen(!isOpen)} // Toggle the open state
          >
            {!scrolled ? (
              <img
                className="navbar-toggler-icon brightness-125 !w-[2.5em] !h-[3vh]"
                src={hamburgerImg}
                alt="Toggle"
              />
            ) : (
              <div>
                <RxHamburgerMenu className="text-black text-4xl" />
              </div>
            )}
          </button>

          <div
            className="offcanvas offcanvas-end"
            id="navbarOffcanvas"
            tabIndex="-1"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title text-light"
                id="offcanvasNavbarLabel"
              >
                <Link to="/" onClick={closeSidebarAndBackdrop}>
                  <img src={icons.Logo} alt="Logo" />
                </Link>
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={closeSidebarAndBackdrop}
              >
                <img src={Xicon} alt="Close" />
              </button>
            </div>

            <div className="offcanvas-body">
              <div className="navbar-nav gulab-header-wrapper flex-grow-1 pe-3">
                {isDesktop && (
                  <NavDropdown
                    title="About"
                    id="basic-nav-dropdown"
                    className="customMenu common_link"
                    show={isOpen}
                    onMouseEnter={() => setIsOpen(true)}
                    onMouseLeave={() => setIsOpen(false)}
                    onClick={() => navigate("/about-us")}
                  >
                    <NavDropdown.Item
                      href="#action/1.1"
                      onClick={() => scrollToSection("I")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Introduction
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#ourgurus"
                      onClick={() => scrollToSection("O")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Our Gurus
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/1.3"
                      onClick={() => scrollToSection("L")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Life at UGMK
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/1.4"
                      onClick={() => scrollToSection("A")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Awards
                    </NavDropdown.Item>
                    <img src={image} alt="Dropdown Icon" className="mt-3" />
                  </NavDropdown>
                )}

                <Link
                  className="nav-item nav-link common_link text-sm md:text-base lg:text-lg"
                  to="/ugmk-prodigies"
                  onClick={closeSidebarAndBackdrop}
                >
                  UGMK Prodigies
                </Link>

                {isDesktop && (
                  <NavDropdown
                    title="Academy"
                    id="basic-nav-dropdown"
                    className="customMenu common_link"
                    show={isShow}
                    onMouseEnter={() => setIsShow(true)}
                    onMouseLeave={() => setIsShow(false)}
                    onClick={() => navigate("/academy")}
                  >
                    <NavDropdown.Item
                      href="/academy"
                      className="text-sm md:text-base lg:text-lg"
                    >
                      How it Works
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.2"
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Why Us
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.3"
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Courses
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.3"
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Workshops
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.3"
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Media/Gallery
                    </NavDropdown.Item>
                    <img src={image} alt="Dropdown Icon" className="mt-3" />
                  </NavDropdown>
                )}

                <div className="hidden md:block">
                  <Link
                    className="nav-item nav-link common_link text-sm md:text-base lg:text-lg"
                    to="/success-stories"
                    onClick={closeSidebarAndBackdrop}
                  >
                    Success Stories
                  </Link>
                </div>

                <div className="hidden md:block">
                  <Link
                    className="nav-item nav-link common_link text-sm md:text-base lg:text-lg"
                    to="/contact-us"
                    onClick={closeSidebarAndBackdrop}
                  >
                    Contact Us
                  </Link>
                </div>

                <Link
                  className="enrollNowBtn common_link text-sm md:text-base lg:text-lg"
                  to="/contact-us"
                  onClick={closeSidebarAndBackdrop}
                >
                  ENROL NOW
                </Link>

                <div className="md:hidden block w-full">
                  <Link
                    className="nav-item nav-link common_link text-sm md:text-base lg:text-lg"
                    to="/about-us"
                    onClick={closeSidebarAndBackdrop}
                  >
                    About
                  </Link>

                  <Link
                    className="nav-item nav-link common_link text-sm md:text-base lg:text-lg"
                    to="/academy"
                    onClick={closeSidebarAndBackdrop}
                  >
                    Academy
                  </Link>

                  <div className="block md:hidden">
                    <Link
                      className="nav-item nav-link common_link text-sm md:text-base lg:text-lg"
                      to="/success-stories"
                      onClick={closeSidebarAndBackdrop}
                    >
                      Success Stories
                    </Link>
                  </div>

                  <Link
                    className="nav-item nav-link common_link text-sm md:text-base lg:text-lg"
                    to="/contact-us"
                    onClick={closeSidebarAndBackdrop}
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
