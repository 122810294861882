import React from "react";
import Slider from "react-slick";
import right from "../../Assets/img/rightarrow.png";
import left from "../../Assets/img/Leftarrow.png";
import { Container } from "react-bootstrap";

const CustomPrevArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute left-[3vw] sm:left-[5vw] md:left-[5.99vw] top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
  >
    <img
      src={left}
      alt="Previous"
      className="w-[20vw] !h-[20vw] sm:w-[20vw] sm:!h-[20vw] md:!w-[5vw] md:!h-[5vw]"
    />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute right-[3vw] sm:right-[5vw] md:right-[5.99vw] top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
  >
    <img
      src={right}
      alt="Next"
      className="w-[20vw] !h-[20vw] sm:!w-[20vw] sm:!h-[20vw] md:!w-[5vw] md:!h-[5vw]"
    />
  </div>
);

export const WorkShopSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768, // Mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="pt-[7vw]">
      <div className="relative">
        <h3 className="md:text-[16.67vh] text-[8vh] text-[#D5B376] font-extrabold absolute md:top-[-65px] top-[-40px] opacity-10">
          Seminar
        </h3>
      </div>
      <Container className="custom-container" data-aos="fade-up">
        <h2 className="heading">Workshops</h2>
      </Container>
      <div className="w-full h-[160.27vw] md:h-[50.1vw] overflow-hidden relative">
        <Slider {...settings} className="w-full h-full">
          <div className="flex items-center justify-center w-[100vw] !h-[160.27vw] md:w-full md:!h-full bg-black relative">
            <img
              src="/home/thumbnail.png"
              alt="Workshop Coming Soon"
              className="w-full h-full object-cover"
            />
            {/* Positioned Text */}
            <h1 className="absolute left-[8vw] bottom-[10vw] z-30 text-[5.2vw] font-bold font-karla text-white">
              COMING SOON...
            </h1>
          </div>
        </Slider>
      </div>
    </div>
  );
};
