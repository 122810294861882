import React from "react";
import Slider from "react-slick";
import right from "../../Assets/img/rightarrow.png";
import left from "../../Assets/img/Leftarrow.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { div } from "framer-motion/client";

// Custom Arrows
const CustomPrevArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute left-[-5vw] md:left-[-1vw] top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
  >
    <img src={left} alt="Previous" className="!w-[17vw] !h-[17vw] md:!w-[4vw] md:!h-[4vw]" />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute right-[-5vw] md:right-[-1vw] top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
  >
    <img src={right} alt="Next" className="!w-[17vw] !h-[17vw] md:!w-[4vw] md:!h-[4vw]" />
  </div>
);

export const AcademySlider3 = () => {
  // Image Array
  const images = [
    "./AcademySliderImg/1.png",
    "./AcademySliderImg/2.png",
    "./AcademySliderImg/3.png",
    "./AcademySliderImg/4.png",
    "./AcademySliderImg/5.png",
    "./AcademySliderImg/6.png",
    "./AcademySliderImg/7.png",
    "./AcademySliderImg/8.png",
    "./AcademySliderImg/9.png",
    "./AcademySliderImg/10.png",
    "./AcademySliderImg/11.png",
    "./AcademySliderImg/12.png",
    
    "./AcademySliderImg/13.png",
    "./AcademySliderImg/14.png",
    "./AcademySliderImg/15.png",
    "./AcademySliderImg/16.png",
"./AcademySliderImg/17.png",
    "./AcademySliderImg/18.png",
  ];
  const Deskimages = [
    "./AcademySliderImg/Desk/1.png",
    "./AcademySliderImg/Desk/2.png",
    "./AcademySliderImg/Desk/3.png",
    "./AcademySliderImg/Desk/4.png",
    "./AcademySliderImg/Desk/5.png",
    "./AcademySliderImg/Desk/6.png",
    "./AcademySliderImg/Desk/7.png",
    "./AcademySliderImg/Desk/8.png",
    "./AcademySliderImg/Desk/9.png",
    "./AcademySliderImg/Desk/10.png",
    "./AcademySliderImg/Desk/11.png",
    "./AcademySliderImg/Desk/12.png",
    "./AcademySliderImg/Desk/13.png",
    "./AcademySliderImg/Desk/14.png",
    "./AcademySliderImg/Desk/15.png",
    "./AcademySliderImg/Desk/16.png",
"./AcademySliderImg/Desk/17.png",
    "./AcademySliderImg/Desk/18.png",
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.98,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
   <div>
     <div className="w-full hidden md:block overflow-hidden px-[7vw] mt-40px-mvw md:mt-0 mb-[6.25vw]">
      <Slider {...settings} className="w-[85vw]">
        {images.map((imgSrc, index) => (
          <div key={index} className="flex items-center justify-center">
            <img
              src={imgSrc}
              alt={`Slide ${index + 1}`}
              className="md:w-[26.4vw] md:h-[35.6vw] w-[90.26vw] h-[108.42vw] object-cover"
            />
          </div>
        ))}
      </Slider>
    </div>
    <div className="w-full md:hidden overflow-hidden px-[7vw] mt-40px-mvw md:mt-0 mb-[6.25vw]">
      <Slider {...settings} className="w-[85vw]">
        {images.map((imgSrc, index) => (
          <div key={index} className="flex items-center justify-center">
            <img
              src={imgSrc}
              alt={`Slide ${index + 1}`}
              className="md:w-[26.4vw] md:h-[35.6vw] w-[90.26vw] h-[108.42vw] object-cover"
            />
          </div>
        ))}
      </Slider>
    </div>
   </div>
  );
};
