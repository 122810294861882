import React, { useEffect, useRef } from "react";
import ustadjiiImg from "../../Assets/img/Ustadji_with_his_sons-removebg-preview.png";
import { useLocation } from "react-router-dom";
const WhyIntroductiontion = ({heading,bgHeading,academy,paragraph,img=false}) => {

  return (
    <div className="overflow-hidden bg-[#FAF6F0]" >
      <div className="relative">
        <div className="top-[0vh] left-0 text-[16vw] md:text-[9.375vw] absolute opacity-10 font-karla font-extrabold text-[#D5B376] ">
        Why Us
        </div>
      </div>
      <div className=" pb-[6.93vw] md:pb-[0vh] pt-[5vw]">
            <div className="px-16px-mvw font-extrabold text-[10.67vw] md:pl-[7.29vw] md:text-[6.25vw] font-garamond text-[#8C1211]"
          >
              Why UGMK Academy?
            </div>
          </div>
      <div className="grid md:grid-cols-[50%_50%] grid-cols-1 w-full pt-[5.36vw] px-[4.27vw] md:px-0"
        
        data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
      >
        <div className="flex md:pl-[7.29vw] flex-col w-full">
         
          <div className="relative w-full md:hidden block ">
          <div className="w-[60.52vw] overflow-hidden absolute left-0 right-[15vw] bottom-0 md:block hidden">
            <img src="./images/imggggg.png" alt="" className="w-full h-full" />
          </div>
          {/* For mobile view, you can add this img tag directly in the single column */}
          <img src={ustadjiiImg} alt="" className="w-full h-auto md:hidden" />
        </div>
        {
          academy ? (
            <div className="flex pt-[6.4vw] md:pt-0 flex-col pb-[4.84vh] items-start text-[4.35vw] md:text-[1.25vw] text-[#1E1E1E] font-karla font-medium  ">
         <p>{paragraph}</p>
          </div>
          ) : (
            <div className="flex pt-[6.4vw] md:pt-0 flex-col pb-[4.84vh] items-start text-[4.35vw] md:text-[1.25vw] text-[#1E1E1E] font-karla font-medium  ">
          It’s rare to find a learning platform that’s a perfect mix of tradition and modernity. We, at UGMK, has a value system that’s rooted in the Rampur-Sahaswan gharana, one of the most prominent schools of Hindustani classical music that’s known for its distinctive style and rich musical heritage. But we also teach and profess the music of today and try to inculcate a perfect mix of a strong base and commercially soaring capabilities in our students. Voice culture is an integral part of our teaching methodology. We help our students develop their vocal techniques to improve the quality, range and power of their voice. This process is essential for singers, actors, public speakers, and anyone who relies on their voice for professional or personal expression.
          </div>
          )
        }
         
        </div>
        <div className="hidden md:block relative w-full">
          <div className="w-[60.52vw] overflow-hidden absolute left-0 right-[15vw] bottom-0 md:block hidden">
          <img src="./images/111.png" alt="" className="w-[41vw] h-[27.5vw] ml-[5vw]" />
          </div>
          {/* For mobile view, you can add this img tag directly in the single column */}
          <img src={ustadjiiImg} alt="" className="w-full h-auto md:hidden" />
        </div>
      </div>
      <div  >

      </div>
    </div>
  );
};

export default WhyIntroductiontion;
