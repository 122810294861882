import React, { useEffect } from "react";
import WhiteBtn from "../../Components/WhiteBtn";
import img1 from "../../Assets/ImgWithBg/course 1.png";
import img2 from "../../Assets/ImgWithBg/course 2.png";
import img3 from "../../Assets/ImgWithBg/course 3.png";
import btnimg from "../../Assets/img/rightArrow.svg";
import { useNavigate } from "react-router-dom";
import img_1 from "../../Assets/ImgWithBg/course 1 mobile.png";
import img_2 from "../../Assets/ImgWithBg/course 2 mobile.png";
import img_3 from "../../Assets/ImgWithBg/course 3 mobile.png";
import img_4 from "../../Assets/ImgWithBg/course 4 mobile.png";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css";

const OurCourses = () => {
  const navigate = useNavigate();
  const items = [
    {
      id: 1,
      image: img1,
      title: "Mountain Landscape",
    },
    {
      id: 2,
      image: "./home/img2.png",
      title: "City Skyline",
      marginTop: "mt-[2.5vw]", // 120px converted to vw
    },
    {
      id: 3,
      image: "./home/img3.png",
      title: "Ocean View",
    },
    {
      id: 4,
      image: "./home/img4.png",
      title: "Forest Trail",
      marginTop: "mt-[2.5vw]", // 120px converted to vw
    },
  ];
  useEffect(() => {
    AOS.init({ duration: 1500, once: false, easing: "ease-in-out" }); // Initialize AOS
  }, []);

  return (
    <div className=" bg-[#FAF6F0] ">
      <div className="relative">
        <div className="hidden md:block top-60px-vw left-0 !overflow-hidden tracking-tight md:tracking-normal text-[16vw] md:text-[9.375vw] absolute opacity-10 font-karla font-extrabold text-[#D5B376]">
          Student Programs
        </div>
        <div className=" block md:hidden top-[0vh] left-0 !overflow-hidden tracking-tight md:tracking-normal text-[16vw] md:text-[9.375vw] absolute opacity-10 font-karla font-extrabold text-[#D5B376]">
          Student Prog
        </div>
      </div>
      <div className="flex flex-col md:px-[7.29vw] px-[4.30vw] pt-[8vw]">
        <div
          className="font-extrabold text-[10.67vw] md:text-[6.25vw] font-garamond text-[#8C1211]"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          Our Courses
        </div>
      </div>
      <div
        className="flex flex-row items-center pt-24px-mvw md:pt-0 justify-between px-[4.30vw] md:px-[7.29vw]"
        data-aos="fade-up" // AOS animation for text container
        data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
        data-aos-offset="150"
      >
        <p className="md:max-w-[55.58vw] text-16px-mvw md:pb-0 pb-30px-mvw md:text-karla-medium-24 font-karla font-medium">
        From film and non-film music to classical, ghazal and devotional - we specialise in all music genres. Abiding by Ustad Ghulam Mustafa Khan sahab's philosophy of 'each disciple is a reflection of the divine light within', we make sure our students realise their music acumen to its ultimate potential. Here's what is offered at UGMK
        </p>
        <div className="hidden md:block">
          <WhiteBtn onClick={() => navigate("/academy")} title={"View All"} />
        </div>
      </div>

      <div
        className="hidden  md:grid grid-cols-1 md:grid-cols-2 pt-[6.25vw]  md:gap-x-[6.77vw] px-[8.50vw] "
        data-aos="fade-up" // AOS animation for text container
        data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
        data-aos-offset="150"
      >
        <div className="flex flex-col gap-0  items-center justify-star">
          <div
            className="  cursor-pointer relative "
            data-aos="fade-up" // AOS animation for text container
            data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
            data-aos-offset="150"
          >
            <div className="md:w-[39.3vw] transition-all duration-600 ease-custom transform hover:scale-[1.02]  w-full hover:shadow-lg  hover:rounded-sm bg-[#F6EFE2] ">
              <div className="absolute top-0 left-20px-vw">
                <img src="/home/01.png" alt="1-svg" />
              </div>
              <img src={img1} alt="" />
            </div>
            <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-40px-vw md:pb-[10.4vw]">
              <h1 className=" uppercase text-karla-semibold-36 font-semibold font-karla ">
                Hindustani Classical
              </h1>
              <div
                className="border-black rounded-full  h-[3vw] w-[3vw] flex items-center justify-center border-[1px]"
                onClick={() => navigate("/academy")}
              >
                <img
                  src={btnimg}
                  alt="arrow icon"
                  className="w-[2vw] md:w-[1.3vw]"
                />
              </div>
            </div>
          </div>
          <div className="transition-transform  duration-600  cursor-pointer ">
            <div className="md:w-[39.3vw] transition-all duration-600 ease-custom transform hover:scale-[1.02] relative  w-full hover:shadow-lg  hover:rounded-sm bg-[#F6EFE2]    ">
              <div className="absolute top-0 left-20px-vw">
                <img src="/home/03.png" alt="1-svg" />
              </div>
              <img src="./home/img3.png" alt="" />
            </div>
            <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-40px-vw">
              <h1 className=" uppercase text-karla-semibold-36 font-semibold font-karla ">
              Film Music
              </h1>
              <div
                className="border-black rounded-full  h-[3vw] w-[3vw] flex items-center justify-center border-[1px]"
                onClick={() => navigate("/academy")}
              >
                <img
                  src={btnimg}
                  alt="arrow icon"
                  className="w-[2vw] md:w-[1.3vw]"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex flex-col  items-center justify-start md:mt-[4.24vw]"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          <div className="transition-transform  duration-600  cursor-pointer ">
            <div className="md:w-[39.3vw] transition-all duration-600 ease-custom transform hover:scale-[1.02]  w-full relative hover:shadow-lg  hover:rounded-sm bg-[#F6EFE2]  ">
              <div className="absolute top-0 left-20px-vw">
                <img src="/home/02.png" alt="1-svg" />
              </div>
              <img src="./home/img2.png" alt=""  className="py-[3vw]"/>
            </div>
            <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-40px-vw md:pb-[10.55vw]">
              <h1 className=" uppercase text-karla-semibold-36 font-semibold font-karla ">
              Ghazal and Semi Classical
              </h1>
              <div
                className="border-black rounded-full  h-[3vw] w-[3vw] flex items-center justify-center border-[1px]"
                onClick={() => navigate("/academy")}
              >
                <img
                  src={btnimg}
                  alt="arrow icon"
                  className="w-[2vw] md:w-[1.3vw]"
                />
              </div>
            </div>
          </div>
          <div className=" cursor-pointer hover:rounded-sm">
            <div className="md:w-[39.3vw] transition-all duration-600 ease-custom transform hover:scale-[1.02]  w-full relative hover:shadow-lg  hover:rounded-sm bg-[#F6EFE2]   ">
              <div className="absolute top-0 left-20px-vw">
                <img src="/home/04.png" alt="1-svg" />
              </div>
              <img src="./home/img5.png" alt="" className="w-full py-[4vw] h-full"/>
            </div>
            <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-40px-vw">
              <h1 className=" uppercase text-karla-semibold-36 font-semibold font-karla ">
                Devotional Music
              </h1>
              <div
                className="border-black rounded-full  h-[3vw] w-[3vw] flex items-center justify-center border-[1px]"
                onClick={() => navigate("/academy")}
              >
                <img
                  src={btnimg}
                  alt="arrow icon"
                  className="w-[2vw] md:w-[1.3vw]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col w-full px-[4.30vw] md:hidden"
        data-aos="fade-up" // AOS animation for text container
        data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
        data-aos-offset="150"
      >
        <div className="flex flex-col">
          <div className="w-full relative bg-[#F6EFE2] ">
            <div className="absolute top-0 left-8px-mvw">
              <img src="/mobile/01.png" alt="1-svg" />
            </div>
            <img src={img_1} alt="" />
          </div>

          <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-47px-vw">
            <div className="flex items-center justify-center">
              <h1 className="uppercase text-[#1E1E1E] text-[5.33vw] font-semibold font-karla tracking-tighter">
                Hindustani Classical
              </h1>
            </div>
            <div
              className="border-[#1E1E1E] rounded-full px-2 py-[2.9vw] flex items-center justify-center border-[1px]"
              onClick={() => navigate("/academy")}
            >
              <img
                src={btnimg}
                alt="arrow icon"
                className="w-[5vw] md:w-[1.3vw]"
              />
            </div>
          </div>
        </div>
        <div
          className="flex flex-col"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          <div className="w-full relative bg-[#F6EFE2] ">
            <div className="absolute top-0 left-8px-mvw">
              <img src="/mobile/02.png" alt="1-svg" />
            </div>
            <img src={img_2} alt="" />
          </div> 




          <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-40px-vw">
            <div className="flex items-center justify-center">
              <h1 className="uppercase text-[#1E1E1E] text-[5.33vw] font-semibold font-karla tracking-tighter">
              Film Music
              </h1>
            </div>
            <div
              className="border-[#1E1E1E] rounded-full px-2 py-[2.9vw] flex items-center justify-center border-[1px]"
              onClick={() => navigate("/about")}
            >
              <img
                src={btnimg}
                alt="arrow icon"
                className="w-[5vw] md:w-[1.3vw]"
              />
            </div>
          </div> 


        </div>
        <div
          className="flex flex-col"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          <div className="w-full relative bg-[#F6EFE2] ">
            <div className="absolute top-0 left-8px-mvw">
              <img src="/mobile/03.png" alt="1-svg" />
            </div>
            <img src={img_3} alt="" />
          </div>

          <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-40px-vw">
            <div className="flex items-center justify-center">
              <h1 className="uppercase text-[#1E1E1E] text-[5.33vw] font-semibold font-karla tracking-tighter">
              Ghazal and Semi Classical
              </h1>
            </div>
            <div
              className="border-[#1E1E1E] rounded-full px-2 py-[2.9vw] flex items-center justify-center border-[1px]"
              onClick={() => navigate("/about")}
            >
              <img
                src={btnimg}
                alt="arrow icon"
                className="w-[5vw] md:w-[1.3vw]"
              />
            </div>
          </div> 

          
        </div>
        <div
          className="flex flex-col"
          data-aos="fade-up" // AOS animation for text container
          data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
          data-aos-offset="150"
        >
          <div className="w-full relative bg-[#F6EFE2] ">
            <div className="absolute top-0 left-8px-mvw">
              <img src="/mobile/04.png" alt="1-svg" />
            </div>
            <img src={img_4} alt="" />
          </div>
          <div className="flex items-center justify-between pt-24px-mvw pb-47px-mvw md:pt-40px-vw">
            <div className="flex items-center justify-center">
              <h1 className="uppercase text-[#1E1E1E] text-[5.33vw] font-semibold font-karla tracking-tighter">
                Devotional Music
              </h1>
            </div>
            <div
              className="border-[#1E1E1E] rounded-full px-2 py-[2.9vw] flex items-center justify-center border-[1px]"
              onClick={() => navigate("/academy")}
            >
              <img
                src={btnimg}
                alt="arrow icon"
                className="w-[5vw] md:w-[1.3vw]"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className=" md:hidden block "
        data-aos="fade-up" // AOS animation for text container
        data-aos-duration="1500" // Increased duration for a smoother effect (2 seconds)
        data-aos-offset="150"
      >
        <div className="mx-[30.67vw]">
          <WhiteBtn onClick={() => navigate("/academy")} title={"View All"} />
        </div>
      </div>
    </div>
  );
};

export default OurCourses;
