import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import right from "../../Assets/img/rightarrow.png";
import left from "../../Assets/img/Leftarrow.png";
import { Container } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

const MediaSlider = ({ ref3 ,title }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [popupVideo, setPopupVideo] = useState(null);

  const videos = [
    {
      id: 1,
      videoId: "mdacs_CkXvw",
      url: "https://www.youtube.com/embed/mdacs_CkXvw?autoplay=1",
      title: "New Release",
      thumbnail: "/home/thumbnail.png",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
    },
    {
      id: 2,
      videoId: "mdacs_CkXvw",
      url: "https://www.youtube.com/embed/mdacs_CkXvw?autoplay=1",
      title: "The Witcher: Blood Origin",
      thumbnail: "/home/thumbnail.png",
      description:
        "Explore the origins of the Witcher universe in this epic tale of magic and monsters.",
    },
    {
      id: 3,
      videoId: "mdacs_CkXvw",
      url: "https://www.youtube.com/embed/mdacs_CkXvw?autoplay=1",
      title: "Spider-Man: A New Beginning",
      thumbnail: "/home/thumbnail.png",
      description:
        "Peter Parker's journey continues as he takes on new villains and navigates the challenges of both his superhero and personal life.",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setActiveIndex(next),
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Tablet & Mobile
        settings: {
          slidesToShow: 1, // Show only one slide on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({ duration: 1500, once: false });
  }, []);

  const handlePlayClick = (videoUrl) => {
    setPopupVideo(videoUrl);
  };

  const closePopup = () => {
    setPopupVideo(null);
  };

  return (
    <div className="bg-[#FAF6F0] pb-40px-mvw md:pb-[9.5vh] mt-[8.88vw]" ref={ref3}>
      <div className="relative">
        <h3 className="md:text-[16.67vh] text-[8vh] text-[#D5B376] font-extrabold absolute md:top-[-65px] top-[-40px] opacity-10">
          Media
        </h3>
      </div>
      <Container className="custom-container" data-aos="fade-up">
        <h2 className="heading">{title}</h2>
      </Container>
      
      <div className="px-[7.29vw]">
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div key={index} className="flex justify-center">
              <div className="relative w-[41.15vw] h-[27.14vw] md:w-[41.15vw] md:h-[27.14vw] w-[90.26vw] h-[59.47vw] overflow-hidden">
                <img
                  src={video.thumbnail}
                  alt="thumbnail"
                  className="w-full h-full object-cover"
                />
                <div
                  onClick={() => handlePlayClick(video.url)}
                  className="absolute inset-0 flex items-center justify-center cursor-pointer"
                >
                  <img
                    src="/home/play_icon.png"
                    alt="Play icon"
                    className="w-[5.26vw] h-[5.26vw]"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {popupVideo && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
          <div className="relative w-[70vw] h-[40vw] bg-black rounded-lg">
            <iframe
              src={popupVideo}
              title="Video Player"
              className="w-full h-full"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
            <button
              className="absolute top-4 right-0 text-white text-3xl bg-red-600 rounded-full w-10 h-10 flex items-center justify-center"
              onClick={closePopup}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute left-[-5vw] md:left-[-2vw] top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
  >
    <img src={left} alt="Previous" className="!h-[16vw] !w-[16vw] md:!h-92px-vw md:!w-92px-vw" />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute right-[-5vw] md:right-[-1vw] top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
  >
    <img src={right} alt="Next" className="!h-[15vw] !w-[15vw] md:!h-92px-vw md:!w-92px-vw" />
  </div>
);

export default MediaSlider;
