import React, { useEffect } from "react";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS
import WhiteBtn from "../../../Components/WhiteBtn";

const UgmkAbout = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Duration of animation (1.5 seconds)
      once: false, // Animation should happen every time the section enters the viewport
    });
  }, []);

  return (
    <>
      <section className="w-full relative bg-[#FAF6F0]">
        <div className="w-full grid md:grid-cols-[40%_60%] grid-cols-1 md:pl-[7.29vw] relative px-16px-mvw md:px-0">
          <div className="text-[#D5B376] md:block hidden pointer-events-none  absolute text-[16vw] top-40px-mvw  md:top-[8.20vw] md:leading-snug left-0 opacity-10 font-karla font-extrabold md:text-karla-extrabold-180">
            UGMK Academy
          </div> 

          <div className="text-[#D5B376] md:hidden tracking-tight block pointer-events-none  whitespace-nowrap overflow-hidden absolute text-[16vw] top-40px-mvw  md:top-[8.20vw] md:leading-snug left-0 opacity-10 font-karla font-extrabold md:text-karla-extrabold-180">
            UGMK Acade
          </div>
          <div
            className="w-full flex flex-col items-start justify-center md:pt-[22.85vh] pt-80px-mvw md:gap-40px-vw"
            data-aos="fade-up" // AOS animation for text container
            data-aos-duration="2000" // Increased duration for a smoother effect (2 seconds)
            data-aos-offset="150" // Start animation a bit earlier as the section enters the viewport
          >
            <h2 className="text-[#8C1211] text-[10.67vw] md:text-ebgaramond-extrabold-120 font-garamond font-extrabold">
              About Us
            </h2>
            <div className="md:hidden py-24px-mvw md:py-0">
              <img
                src="./home/ustadjii.png"
                alt="ustad"
                className="w-full h-full"
                data-aos="fade-in" // AOS animation for the first image
                data-aos-duration="2000" // Increased duration for a smoother effect (2 seconds)
                data-aos-offset="150" // Start animation a bit earlier
              />
            </div>
            <div className="md:space-y-40px-vh space-y-40px-mvh leading-snug md:max-w-[94%] text-[#1E1E1E] opacity-100 md:text-karla-medium-24 font-karla font-medium">
              <p>
              Rooted in the value system of honesty, self-belief and humility professed by Padma Vibhushan Ustad Ghulam Mustafa Khan, the Academy aims at offering an opportunity to anybody who aspires to sing. Be it a 4-year-old or someone who's 80 -- we believe music is the right of anyone who dreams to sing, it's for one and all. Hence, our Academy offers a one-of-a-kind opportunity to learn a range of genres from Ustad Ghulam Mustafa Khan's sons and students, who make the faculty of the Academy.
              </p>
            </div>
            <div className="md:pb-[5.42vw] pb-60px-mvw md:mt-8px-vh mt-40px-vh">
              <WhiteBtn title={"KNOW MORE"} />
            </div>
          </div>
          <div
            className="hidden md:block md:pt-[5.42vw]"
            data-aos="fade-up" // AOS animation for the second image coming from the bottom
            data-aos-duration="2500" // Longer duration for more smoothness (2.5 seconds)
            data-aos-offset="150" // Start animation a bit earlier
          >
            <img src="./images/ustadji.png" alt="damru" className="w-full h-full" />
          </div>
        </div>
      </section>
    </>
  );
};

export default UgmkAbout;
